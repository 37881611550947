import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProductsService } from '../../products.service';


@Component({
  selector: 'app-stepper-form2',
  templateUrl: './stepper-form2.component.html',
  styleUrls: ['./stepper-form2.component.scss']
})
export class StepperForm2Component implements OnInit {
  //Stepper header
  step: number = 2;
  typeHair: any = '';
  thickHair: any = '';
  lengthHair: number = 2;
  messageError = false;

  typeHairArr: Array<any> = [
    {type: 'Liso', value: 1},
    {type: 'Ondulado', value: 1},
    {type: 'Crespo', value:0},
    {type:'Afro', value: 0}
  ];

  thickHairArr: Array<any> = [
    {type: 'Fino', value: 1},
    {type: 'Normal', value: 1},
    {type: 'Grueso', value:2}
  ];

  lengthHairOpt: Options = {
    showTicksValues: true,
    stepsArray: [
      {value: 1, legend: 'corto'},
      {value: 2, legend: 'hasta los hombros'},
      {value: 3, legend: 'largo'},
    ]
  };

  public errors: boolean[] = new Array();
  public btnNext: boolean = false;
  isOpen: string = '';

  constructor(private router: Router, private location: Location, private productService: ProductsService) { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  selectedTypeHair(_type: any){
    
    if(this.typeHair.type === _type.type) {
      this.typeHair = '';
    } else {
      this.typeHair = _type;
    }
    this.onReview(0);
  }
  selectedThickHair(_type: any){
    if(this.thickHair.type === _type.type) {
      this.thickHair = '';
    } else {
      this.thickHair = _type;
    }
    this.onReview(1);
  }

  valueSlider(_select: number):any {
    var data = {};

      if(_select === 1) {
        data =  {value: 2, legend: 'corto'};
      } else if (_select === 2) {
        data =  {value: 1, legend: 'hombros'};
      } else {
        data = {value: 1, legend: 'largo'};
      }
      return data;
  } 
  
  submit() {
    this.errors[0] = this.typeHair === '';
    this.errors[1] = this.thickHair === '';
    this.errors[2] = this.lengthHair === 0;
  
    var exist = this.errors.includes(true);

    if (!exist) {

      var data = {
        typeHair: this.typeHair,
        thickHair: this.thickHair,
        lengthHair: this.valueSlider(this.lengthHair)
      };

      this.productService.getDataOneStep(data);
      this.goNext()
    } else {
      this.messageError = true;
      this.btnNext = false;
    }
  }

  revButton() {
    if (this.typeHair !== '' &&
    this.thickHair !== '' && 
    this.lengthHair !== 0) {
      this.messageError = false;
      this.btnNext = true;
    }
  }
  onReview(_position: number) {
    if(_position === 0){
      this.errors[0] = this.typeHair === '';
    }      
    else if(_position === 1){
      this.errors[1] = this.thickHair === '';
    }
    else {
      this.errors[2] = this.lengthHair === 0;
    }
    this.revButton();
  }

  goNext() {
    this.router.navigate(['questions', 'paso3']);
  }
  goBack() {
    this.location.back();
  }
  isOpenfn(_id:string) {
    if(this.isOpen === _id) {
      this.isOpen = '';
    } else {
      this.isOpen = _id;
    }
  }
  isClose() {
    this.isOpen = '';
  }

}
