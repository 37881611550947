import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor() { }

  private userData: any;
  private stepOne: any;
  private stepTwo: any;

  private score: number;
  private scoreOne: number;
  private scoreTwo: number;
  private collection: any;
  private product:any[] = new Array();

  getDataUser(_data): void {
    this.userData = _data;
    console.info(this.userData);
  }
  getDataOneStep(_data): void {
    this.stepOne = _data;
    this.scoreOne =  _data.typeHair.value + _data.thickHair.value + _data.lengthHair.value;
  }
  getDataTwoStep(_data): void {
    this.stepTwo = _data;

    var chemicalScore = 0;
    var productScore = 0;
    var challengerScore = 0;

    for (var _i = 0; _i < _data.chemical.length; _i++) {
      var chem = _data.chemical[_i];
      chemicalScore = chemicalScore + chem.value;
      //console.log(chem.value);
    }
    
    for (var _i = 0; _i < _data.product.length; _i++) {
      var prod = _data.product[_i];
      productScore = productScore + prod.value;
      //console.log(prod.value);
    }

    for (var _i = 0; _i < _data.challenger.length; _i++) {
      var chall = _data.challenger[_i];
      challengerScore = challengerScore + chall.value;
      // console.log(chall.value);
    }
    this.scoreTwo = _data.washHair.value + chemicalScore + productScore + challengerScore;
  }

  calculateRecommend(){
    this.score = this.scoreOne + this.scoreTwo;
    this.collection = [];
    this.product = [];

    this.collection = this.selectCollection(this.score);

    console.log(this.stepTwo);
    
    var oleoExist = this.stepTwo.product.indexOf(this.stepTwo.product.find( x => x.btn == "Òleo")) !== -1;
    var shampooSeco = this.stepTwo.product.indexOf(this.stepTwo.product.find( x => x.btn == "Shampoo en seco")) !== -1;
    var grasoso = this.stepTwo.challenger.indexOf(this.stepTwo.challenger.find( x => x.btn == "Grasoso")) !== -1;
    var cremaPeinar = this.stepTwo.product.indexOf(this.stepTwo.product.find( x => x.btn == "Crema para peinar")) !== -1;
    var noBrilla = this.stepTwo.challenger.indexOf(this.stepTwo.challenger.find( x => x.btn == "No brilla")) !== -1;
    var pesado = this.stepTwo.challenger.indexOf(this.stepTwo.challenger.find( x => x.btn == "Pelo se ve pesado")) !== -1;
    var enrredado = this.stepTwo.challenger.indexOf(this.stepTwo.challenger.find( x => x.btn == "Se enreda mucho")) !== -1;
    var conFrizz = this.stepTwo.challenger.indexOf(this.stepTwo.challenger.find( x => x.btn == "Tiene mucho Frizz")) !== -1;

    //Existe en seleccion de productos
    var existCreamInResult = this.product.indexOf(this.product.find( x => x.id == "a"));
    
    // Rizos Definidos > Agua Micellar > Brillo extremo

    // Si selecciona No brilla --- Coleccion(Brillo extremo)
    if(noBrilla) {
      this.collection = this.collectionList[3];
    }

    // Si selecciona Pelo se ve pesado o Grasoso y no brilla --- Coleccion(Agua micelar)
    if (grasoso || pesado) {
      this.collection = this.collectionList[5];
    }
    
    // Si seleccionó tipo de pelo Crespo o Afro && no se hace permanente ---- Coleccion(Rizos Definido) --- PostDucha(Crema Peinar Rizos y Foam) 
    if((this.stepOne.typeHair.type === 'Crespo' || this.stepOne.typeHair.type === 'Afro') && this.stepTwo.chemical[0].btn !== 'Me hago permanentes') {
      this.collection = this.collectionList[4];
    }

    // Si selecciona Crema para peinar --- postDucha(Crema Peinar)
    if (cremaPeinar || this.stepOne.typeHair.type === 'Crespo' || this.stepOne.typeHair.type === 'Afro') {
      this.product.push(this.productsList[0]);
    }

    //  Si selecciona pelo corto o hasta los hombros o Òleo --- PostDucha(Óleo Pantene)
    if (this.stepOne.lengthHair.legend === 'corto' || this.stepOne.lengthHair.legend === 'hombros' || oleoExist )   {
      this.product.push(this.productsList[1]);
    }
    
    // Si selecciona lavado 1-3 veces o Shampoo en seco o Grasoso --- PostDucha(Shampoo en seco)
    if (this.stepTwo.washHair.value === 1.5 || shampooSeco || grasoso) {
      this.product.push(this.productsList[2]);
    }

    // Si Se enreda mucho o Tiene mucho Frizz --- PostDucha(Booster)
    if (enrredado || conFrizz) {
      this.product.push(this.productsList[3]);
    }

    // si no se seleccion un producto agregara uno en base a la colleccion designada
    if(this.product.length === 0 && this.collection) {
      this.product.push(this.selectProduct(this.collection.idProd));
    }
    
  }

  selectCollection(_score: number):any {
    var collection: object;
    for (var _i = 0; _i < this.collectionList.length; _i++) {
      if(this.collectionList[_i]['range']['min'] <= _score && this.collectionList[_i]['range']['max'] >= _score) {
        collection = this.collectionList[_i]; 
      }      
    }
    return collection;
  }

  selectProduct(_charId: string):any {
    var product: object;
    for (var _i = 0; _i < this.productsList.length; _i++) {
      if(this.productsList[_i].id === _charId ) {
        product = this.productsList[_i]; 
      }      
    }
    return product;
  }

  getResult(): any {
    var data = {
      score: this.score,
      collection: this.collection,
      product: this.product
    };
    return data;
  }

  collectionList = [
    {
      nombre: 'Colección Restauración',
      detalle: 'Utiliza el Shampoo y el Acondicionador 3 Minute Miracle todos los días para reparar el daño* extremo de tu pelo en solo 3 minutos',
      nota: '*daño al brillo y suavidad, usando el sistema Pantene.',
      url:'#',
      productList: [
        'Shampoo Restauración',
        'Acondicionador Restauración',
        'Acondicionador 3 minutos miracle Restauración',
        'Tratamiento capilar 3 minutos de rescate',
        'Mascarilla intensiva Restauración'
      ],
      range: {
        min: 8,
        max: 10
      },
      images: [
        '/assets/images/product/restauracion/shampoo.png',
        '/assets/images/product/restauracion/acondicionador.png',
        '/assets/images/product/restauracion/acondicionador_3M.png',
        '/assets/images/product/restauracion/tratamiento_capilar.png',
        '/assets/images/product/restauracion/mascarilla_intensiva.png'
      ],
      idProd: 'b'
    },
    {
      nombre: 'Colección Fuerza y Reconstrucción',
      detalle: 'Utiliza el Shampoo y el Acondicionador 3 Minute Miracle para un pelo hasta 10 veces más fuerte*',
      nota: '*Fuerza contra el daño mecánico vs shampoo sin ingredientes acondicionadores.',
      url:'#',
      productList: [
        'Shampoo Fuerza y Reconstrucción',
        'Acondicionador Fuerza y Reconstrucción',
        'Acondicionador 3 minutos miracle Fuerza y Reconstrucción'
      ],
      range: {
        min: 10.5,
        max: 12.5
      },
      images: [
        '/assets/images/product/fuerza_y_reconstruccion/shampoo.png',
        '/assets/images/product/fuerza_y_reconstruccion/acondicionador.png',
        '/assets/images/product/fuerza_y_reconstruccion/acondicionador_3M.png'
      ],
      idProd: 'a'
    },
    {
      nombre: 'Colección Hidratación Extrema',
      detalle: 'Utiliza el Shampoo y el Acondicionador 3 Minute Miracle para un pelo hasta 10 veces más fuerte*',
      nota: '*Fuerza contra el daño mecánico vs shampoo sin ingredientes acondicionadores.',
      url:'#',
      productList: [
        'Shampoo Hidratación Extrema',
        'Acondicionador Hidratación Extrema',
        'Acondicionador 3 minutos miracle Hidratación Extrema',
        'Tratamiento capilar Hidrosellador'
      ],
      range: {
        min: 6,
        max: 7.5
      },
      images: [
        '/assets/images/product/hidratacion_extrema/shampoo.png',
        '/assets/images/product/hidratacion_extrema/acondicionador.png',
        '/assets/images/product/hidratacion_extrema/acondicionador_3M.png',
        '/assets/images/product/hidratacion_extrema/hidrosellador.png'
      ],
      idProd: 'c'
    },
    {
      nombre: 'Colección Brillo Extremo',
      detalle: 'Utiliza el Shampoo y Acondicionador para un pelo 3 veces más brillante y sedoso',
      nota: '',
      url:'#',
      productList: [
        'Shampoo Brillo Extremo',
        'Acondicionador Brillo Extremo'
      ],
      range: {
        min: 4,
        max: 5.5
      },
      images: [
        '/assets/images/product/brillo_extremo/shampoo.png',
        '/assets/images/product/brillo_extremo/acondicionador.png'
      ],
      idProd: 'd'
    },
    {
      nombre: 'Colección Rizos Definidos',
      detalle: 'Utiliza el Shampoo y el Acondicionador 3 Minute Miracle para tener unos rizos definidos por más de 24 horas',
      nota: '',
      url:'#',
      productList: [
        'Shampoo Rizos Definidos',
        'Acondicionador Rizos Definidos',
        'Acondicionador 3 minutos miracle Rizos Definidos',
        'Mascarilla intensiva Rizos Definidos'
      ],
      range: {
        min: 0,
        max: 0
      },
      images: [
        '/assets/images/product/rizos_definidos/shampoo.png',
        '/assets/images/product/rizos_definidos/acondicionador.png',
        '/assets/images/product/rizos_definidos/acondicionador_3M.png',
        '/assets/images/product/rizos_definidos/mascarilla_intensiva.png'
      ],
      idProd: 'e'
    },
    {
      nombre: 'Colección Agua Micelar',
      detalle: 'Para tu pelo graso, utiliza el Shampoo y Acondicionador Agua Micelar, que purifica las raíces e hidrata hasta las puntas',
      nota: '',
      url:'#',
      productList: [
        'Shampoo rizos Micelar purifica & hidrata',
        'Acondicionador Micelar purifica & hidrata'
      ],
      range: {
        min: 2,
        max: 3.5
      },
      images: [
        '/assets/images/product/micelar/shampoo.png',
        '/assets/images/product/micelar/acondicionador.png'
      ],
      idProd: null
    }
  ];

  productsList = [
    {
      id: 'a',
      nombre: 'Crema para Peinar',
      detalle: 'Utilízala como ayuda para peinar tu pelo, adicional, te ayuda a proteger tu pelo un 95% más contra el daño* del estilizado',
      nota: '*Daño al brillo y suavidad',
      url:'#',
      images: [
        '/assets/images/product/crema_para_peinar/anti_frizz.png',
        '/assets/images/product/crema_para_peinar/fuerza.png',
        '/assets/images/product/crema_para_peinar/repara.png'
      ]
    },
    {
      id: 'b',
      nombre: 'Óleo / Aceite esencia y vitamina',
      detalle: 'Dale fragancia y sella visiblemente tus puntas abiertas con el óleo capilar de Pantene',
      nota: '',
      url:'#',
      images:['/assets/images/product/oleo/esencia_vitamina.png']
    },
    {
      id: 'c',
      nombre: 'Dry Shampoo',
      detalle: 'Para extender la limpieza entre lavadas, utiliza un shampoo en seco, que absorve la grasa y refresca el pelo sin necesidad de lavarlo',
      nota: '',
      url:'#',
      images: ['/assets/images/product/shampoo_en_seco/shampoo.png']
    },
    {
      id: 'd',
      nombre: 'Micelar Booster de Hidratación',
      detalle: 'Utiliza el Booster de hidratación de Pantene, para darle brillo y un rico aroma a tu pelo, no requiere enjuague.',
      nota: '',
      url:'#',
      images: ['/assets/images/product/booster/hidratacion.png']
    },
    {
      id: 'e',
      nombre: 'Rizos Definidos Espuma',
      detalle: 'RIZOS DEFINIDOS POR +24 HS. Espuma moldeadora de Rizos. Rizos 99% más definidos todo el día, más resistentes a la humedad y con volumen controlado*',
      nota: '',
      url:'#',
      images: ['/assets/images/product/mousse/rizos_definidos.png']
    }
  ];

}





