import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProductsService } from '../../products.service';

@Component({
  selector: 'app-stepper-form3',
  templateUrl: './stepper-form3.component.html',
  styleUrls: ['./stepper-form3.component.scss']
})
export class StepperForm3Component implements OnInit {
  step: number = 3;
  washHair: number = 1;
  messageError = false;

  washHairOpt: Options = {
    showTicksValues: true,
    stepsArray: [
      {value: 1, legend: '1-3 veces'},
      {value: 2, legend: '3-5 veces'},
      {value: 3, legend: '6 o más'}
    ]
  };

  chemicalArr: Array <any> =[
    {btn: 'Me coloreo el pelo', value:3},
    {btn: 'Alisado con Keratina', value: 3},
    {btn: 'Me hago permanentes', value:3},
    {btn: 'Ninguno de los anteriores', value:0}
  ];
  
  productArr: Array <any> = [
    {btn:'Shampoo', value:0},
    {btn:'Acondicionador', value:0},
    {btn:'Crema para peinar', value:0},
    {btn:'Òleo', value:0},
    {btn:'Crema de Tratamiento', value:0},
    {btn:'Shampoo en seco', value:0},
    {btn:'Mascarilla para el pelo', value:0},
    {btn:'Ampollas', value:0},
    {btn:'Sérum', value:0},
    {btn:'Pre-Shampoo', value:0}
  ];

  challengerArr: Array <any> = [
    { btn: 'Mis rulos no se definen', value: 0 },
    { btn: 'Sequedad', value: 3 },
    { btn: 'Daño /quiebre del pelo', value: 3 },
    { btn: 'Mi pelo largo no coopera', value: 3 },
    { btn: 'No tengo Volumen', value: 0 },
    { btn: 'No brilla', value: 2 },
    { btn: 'Pelo se ve pesado', value: 0 },
    { btn: 'No se mantiene limpio en el día', value: 1 },
    { btn: 'Grasoso', value: 0 },
    { btn: 'Se enreda mucho', value: 2},
    { btn: 'Tiene mucho Frizz', value: 2 },
    { btn: 'Pérdida de pelo', value: 3 },
    { btn: 'Otros', value: 0 }
  ]; 

  challengerSelected:any[] = new Array();
  productSelected:any[] = new Array();
  chemicalSelected:any[] = new Array();
  public errors: boolean[] = new Array();
  public btnNext: boolean = false;
  isOpen: string = '';

  constructor(private router: Router, private location: Location, private productService: ProductsService) { }

  ngOnInit() {
    window.scrollTo(0,0);
    //this.chemicalSelected = [{btn: 'Ninguno de los anteriores', value:4}];
  }

  valueSlider(_select: number):any {
    var data = {};

    if(_select === 1) {
      data = {value: 1.5, legend: '1-3 veces'};
    } else if (_select === 2) {
      data =  {value: 0, legend: '3-5 veces'};
    } else {
      data =  {value: 0, legend: '6 o más'};
    }
      return data;
  } 

  submit() {
    this.errors[0] = this.washHair === 0;
    this.errors[1] = this.chemicalSelected.length === 0;
    this.errors[2] = this.productSelected.length === 0;
    this.errors[3] = this.challengerSelected.length === 0;
    
    var exist = this.errors.includes(true);
    if (!exist) {

      var data = {
        washHair: this.valueSlider(this.washHair),
        chemical: this.chemicalSelected,
        product: this.productSelected,
        challenger: this.challengerSelected
      };

      this.productService.getDataTwoStep(data);
      this.productService.calculateRecommend();
      this.goNext()
    } else {
      this.messageError = true;
      this.btnNext = false;
    }
  }

  revButton() {
    if (this.washHair !== 0 &&
    this.chemicalSelected.length !== 0 &&
    this.productSelected.length  !== 0 &&
    this.challengerSelected.length !== 0 ) {
      this.messageError = false;
      this.btnNext = true;
    }
  }
  onReview(_position: number) {
    if(_position === 0){
      this.errors[0] = this.washHair === 0;
    }      
    else if(_position === 1){
      this.errors[1] = this.chemicalSelected.length === 0;
    }
    else if(_position === 2) {
      this.errors[2] = this.productSelected.length === 0;
    }
    else {
      this.errors[3] = this.challengerSelected.length === 0;
    }
    this.revButton();
  }

  selectProduct(_product: any ) {
    var position = this.productSelected.indexOf(_product);
      if(position === -1){
        //if(this.productSelected.length <= 1 ){
          this.productSelected.push(_product);
        //}
      } else {
        this.productSelected.splice(position,1);
      }
  }

  selectChemical(_chemical: any ) {
    //var position = this.chemicalSelected.indexOf(_chemical);

    this.chemicalSelected = [];

    this.chemicalSelected.push(_chemical);


    // if(_chemical === this.chemicalArr[3]){
    //   if(position === -1){
    //     this.chemicalSelected = [];
    //     this.chemicalSelected.push(_chemical);
    //   } else {
    //     this.chemicalSelected.splice(position,1);
    //   }
    // } else {  
    //   if(position === -1 ){
    //     if( this.chemicalSelected.length < 1){
    //       this.chemicalSelected.push(_chemical);
    //     }
    //     var pos1 = this.chemicalSelected.indexOf(this.chemicalArr[3]);
    //     if(pos1 !== -1) {
    //       this.chemicalSelected.splice(pos1, 1);
    //     }
    //   } else {
    //     this.chemicalSelected.splice(position,1);
    //   }
    // }
  }

  goNext() {
    this.router.navigate(['recomendacion']);
  }
  goBack() {
    this.location.back();
  }
  isOpenfn(_id:string) {
    if(this.isOpen === _id) {
      this.isOpen = '';
    } else {
      this.isOpen = _id;
    }
  }
  isClose() {
    this.isOpen = '';
  }
  selectChallenger(_challenger: any ) {
    var position = this.challengerSelected.indexOf(_challenger);
      if(position === -1){
        if(this.challengerSelected.length <= 1){
        this.challengerSelected.push(_challenger);}
      } else {
        this.challengerSelected.splice(position,1);
      }
  }
}
