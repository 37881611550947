import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss']
})
export class InfoPopoverComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  
  isOpen: string = '';

  constructor() { }

  ngOnInit() {
    this.isOpen = '';
  }
  isOpenfn(_id:string) {
    if(this.isOpen === _id) {
      this.isOpen = '';
    } else {
      this.isOpen = _id;
    }
  }
  isClose() {
    this.isOpen = '';
  }

}
