import { Component, OnInit, Inject, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { ProductsService } from '../../products.service';
// import { ProductsService } from '../../products.service';

export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-recommend',
  templateUrl: 'recommend.component.html',
  styleUrls: ['recommend.component.scss']
})
export class RecommendComponent implements OnInit {
  result: any;

  thanksMessage: object = {
    nombre: '¡Gracias!',
    detalle:'Gracias por compartir tus comentarios. ¡Nos estás ayudando a mejorar nuestras recomendaciones para más días fabulosos!'
  };

  constructor(private router: Router, private location: Location, public dialog: MatDialog, private productService: ProductsService) {}

  ngOnInit() {
    window.scrollTo(0,0);
    this.result = this.productService.getResult();

    if(!this.result.collection) {
      this.again();
    }
  }

  goBack() {
    this.location.back();
  }

  openDialogCollection(): void {
    var data = this.productService.getResult();
    const dialogRef = this.dialog.open(ModalData, {
    //  width: '70%'
      data: data.collection
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openDialogProduct(_index): void {
    var data = this.productService.getResult();
    const dialogRef = this.dialog.open(ModalData, {
    //  width: '70%'
      data: data.product[_index]
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogThanks(): void {
    const dialogRef = this.dialog.open(ModalData, {
      data: this.thanksMessage
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  again() {
    this.router.navigate(['questions', 'paso1']);
  }
  
}

@Component({
  selector: 'modal',
  templateUrl: 'modal.html',
  styleUrls: ['recommend.component.scss']
})

export class ModalData {

  constructor(
    public dialogRef: MatDialogRef<ModalData>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}