import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrls: ['star-rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() max: number = 5;
  @Input() rating: number;

  stars: object = {
    fill: 0,
    half: 0,
    empty: 0
  };

  constructor() { }

  ngOnInit() {   
    if(this.rating % 1 == 0) {
      this.stars['fill'] = new Array(Math.floor(this.rating));
      this.stars['empty'] = new Array(this.max - this.rating);
    }
    else {
      this.stars['fill'] = new Array(Math.floor(this.rating));
      this.stars['half'] = new Array(1);
      this.stars['empty'] = new Array(this.max - Math.ceil(this.rating));
    }
  }
}
