import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProductsService } from '../../products.service';


@Component({
  selector: 'stepper-form',
  templateUrl: './stepper-form.component.html',
  styleUrls: ['./stepper-form.component.scss']
})
export class StepperFormComponent implements OnInit {
  //Stepper header
  step: number = 1;
  //slider selector
  valueAge: number = 0;
  maxValue: number = 4;
  genero: number = 0;
  name: string  = '';
  lastName: string  = '';
  birthday: any = '';
  gender: number = 0;
  email: string = '';
  startDate = new Date(1990, 0, 1);
  messageError = false;


  public errors: boolean[] = new Array();
  public btnNext: boolean = false;
  isOpen: string = '';
  private emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private router: Router, private location: Location, private productService: ProductsService) { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  submit() {
    this.errors[0] = this.name === '';
    this.errors[1] = this.lastName === '';
    this.errors[2] = this.birthday === '';
    this.errors[3] = this.gender === 0;
    this.errors[4] = this.email === '';
    this.errors[5] = this.email && !this.emailRegex.test(this.email);
    var exist = this.errors.includes(true);

    if (!exist) {
      var genderStr = 'Masculino';

      if(this.gender === 1) {
        genderStr = 'Femenino';
      } else if (this.gender === 3) {
        genderStr = 'Prefiero no responder';
      }

      var userData = {
        name: this.name,
        lastname: this.lastName,
        birthdate: this.birthday,
        gender: genderStr,
        email: this.email
      };

      this.productService.getDataUser(userData);
      this.goNext()
    } else {
      this.messageError = true;
      this.btnNext = false;

      // setTimeout(function(){
      //   this.messageError = false;
      // }, 500);

    }
  }
  revButton() {
    if (this.name !== '' &&
    this.lastName !== '' &&
    this.birthday !== ''&&
    this.gender !== 0 ) {
      this.messageError = false;
      this.btnNext = true;
    }
  }
  onReview(_position: number) {
    if(_position === 0){
      this.errors[0] = this.name === '';
      this.revButton();
    }      
    else if(_position === 1){
      this.errors[1] = this.lastName === '';
      this.revButton();
    }
    else if(_position === 2) {
      this.errors[2] = this.birthday === '';
      this.revButton();
    }
    else if(_position === 4) {
      this.errors[4] = this.email === '';
      if (this.errors[5]) {
        this.errors[5] = this.email && !this.emailRegex.test(this.email);
      }
      this.revButton();
    }
    else if(_position === 5) {
      this.errors[5] = this.email && !this.emailRegex.test(this.email);
      this.revButton();
    }
    else {
      this.errors[3] = this.gender === 0;
      this.revButton();
    }
  }
  

  goNext() {
    this.router.navigate(['questions', 'paso2']);
  }
  goBack() {
    this.location.back();
  }
  isOpenfn(_id:string) {
    if(this.isOpen === _id) {
      this.isOpen = '';
    } else {
      this.isOpen = _id;
    }
  }
  isClose() {
    this.isOpen = '';
  }
}
