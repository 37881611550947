import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
import {TransferHttpCacheModule} from '@nguniversal/common';

// Cookies
import { CookieService } from 'ngx-cookie-service';

// Animations
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialComponents } from './materialComponents';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

//Components
import { HomeComponent } from './components/home/home.component';
import { StepperFormComponent } from './components/stepper-form/stepper-form.component';
import { PurposeComponent } from './components/purpose/purpose.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { InfoPopoverComponent } from './components/info-popover/info-popover.component';
import { StepperForm2Component } from './components/stepper-form2/stepper-form2.component';
import { StepperForm3Component } from './components/stepper-form3/stepper-form3.component';
import { RecommendComponent, ModalData } from './components/recommend/recommend.component';
import { RatingComponent } from './components/rating/rating.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    StepperFormComponent,
    PurposeComponent,
    ProgressBarComponent,
    InfoPopoverComponent,
    StepperForm2Component,
    StepperForm3Component,
    RecommendComponent,
    RatingComponent,
    ModalData,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'my-app'}),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full'},
      { path: 'purpose', component: PurposeComponent },
      { path: 'questions/paso1', component: StepperFormComponent },
      { path: 'questions/paso2', component: StepperForm2Component },
      { path: 'questions/paso3', component: StepperForm3Component },
      { path: 'recomendacion', component: RecommendComponent },
    ]),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    MaterialComponents,
    FormsModule,
    ReactiveFormsModule,
    Ng5SliderModule,
  ],
  entryComponents: [ModalData],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
